import React from "react"
import Link from 'gatsby-link'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { createUrlAlias } from "../../utils/url"
import _ from "lodash"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import styles from "../../components/styles"

function getGroupMarks(data) {
  let result = _.sortBy(data, obj => obj.name)
  result = _.groupBy(result, obj => /\S/.exec(obj.name))
  return result
}

const style = theme => ({
  ...styles(theme),
})

const CategoryTemplate = ({ pageContext: { data }, classes }) => (
  <Layout>
    <SEO title={data.name} />
      <div>
        <span><Link to={`/catalog/`}>Продукция</Link></span>
        <span> > </span>
        <span>{data.name}</span>
      </div>
      <main>
        {Object.entries(getGroupMarks(data.marks)).map(
          entry => (
            <React.Fragment>
              <h3>{entry[0]}</h3>
              <ul className={classes.list}>
                  {_.sortBy(entry[1], obj => parseFloat(/10+|50|75|6|1|0.66/.exec(obj.name.replace(',','.')))).map(mark => (
                    <li><a href={`/cable/${createUrlAlias(mark.name)}/`} className={classes.item}>{mark.name}</a></li>
                  ))}
                </ul>
            </React.Fragment>
          ))}
      </main>
  </Layout>
)

CategoryTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(CategoryTemplate)


